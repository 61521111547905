import { Button, TextButton } from '@redislabsdev/redis-ui-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useHistory } from 'react-router-dom';
import { useNewCustomerLoginStore } from 'screens/NewCustomer/store/useNewCustomerLoginStore';
import useNavigateToNewSubscription from 'hooks/useNavigateToNewSubscription';
import { DatabaseIcon, SubscriptionsIcon, WandIcon } from '@redislabsdev/redis-ui-icons';
import { FreeDbFormProps } from '../../FreeDb.types';
import * as S from './FreeDbForm.style';
import FreeDbCloudVendor from '../FreeDbCloudVendor/FreeDbCloudVendor';
import { setShowRedisStackCongratulations } from '../../../../../store/databases/databases.actions';
import { buildPath, routes } from '../../../../../utils/constants/routes';
import useNewRedisOptInCloudRegionSelectionProps from '../FreeDbCloudVendor/hooks/useNewRedisOptInCloudRegionSelectionProps';
import FreeDbBanner from './components/FreeDbBanner/FreeDbBanner';
import useFreeDbForm from './hooks/useFreeDbForm';

const FreeDbForm = ({ setShowLoader }: FreeDbFormProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newCustomer.freeDB;
  const history = useHistory();
  const { navigateToNewSubscription } = useNavigateToNewSubscription();
  const dispatch = useDispatch();
  const { setIsFirstLogin } = useNewCustomerLoginStore();

  const {
    freePlans,
    vendorRegionPlanMapper,
    selectedRegion,
    selectedVendor,
    setSelectedRegion,
    setSelectedVendor,
    ...newRedisOptInProps
  } = useNewRedisOptInCloudRegionSelectionProps();

  const { createFreeDb } = useFreeDbForm({
    freePlans,
    vendorRegionPlanMapper,
    selectedRegion,
    selectedVendor
  });

  const handleFreeSubError = () => {
    setShowLoader(false);
    setIsFirstLogin(false);
    history.push(routes.createSubscription.essential);
  };

  const handleFreeDbError = (subscriptionId: number) => {
    setShowLoader(false);
    setIsFirstLogin(false);
    history.push(buildPath(routes.subscriptions.subscription.db.createBdb, { subscriptionId }));
  };

  const handleFreeDbSuccess = (bdb: Bdb) => {
    if (bdb?.is_redis_stack) {
      dispatch(setShowRedisStackCongratulations(true));
    }

    setShowLoader(false);
    setIsFirstLogin(false);
    history.push(
      buildPath(routes.databases.viewBdb.configuration, {
        subscriptionId: bdb.subscription,
        databaseId: bdb.id
      })
    );
  };

  const handleOnClickCreateFreeDatabase = () => {
    createFreeDb({ handleFreeSubError, handleFreeDbError, handleFreeDbSuccess });
    setShowLoader(true);
  };

  const handleOnSkip = () => {
    setIsFirstLogin(false);
    navigateToNewSubscription({ isFreePlan: true });
  };

  return (
    <>
      <S.Header>
        <S.Title size="XL">{t(keyPrefix.title)}</S.Title>
        <S.SubTitleContainer>
          <S.SubTitle variant="semiBold">{t(keyPrefix.tryRedisFor)}</S.SubTitle>
          <S.IconTextWrapper>
            <DatabaseIcon color="neutral800" />
            <S.SubTitle>{t(keyPrefix.cache)}</S.SubTitle>
          </S.IconTextWrapper>
          <S.IconTextWrapper>
            <WandIcon color="neutral800" />
            <S.SubTitle>{t(keyPrefix.vectorSearch)}</S.SubTitle>
          </S.IconTextWrapper>
          <S.IconTextWrapper>
            <SubscriptionsIcon color="neutral800" />
            <S.SubTitle>{t(keyPrefix.databases)}</S.SubTitle>
          </S.IconTextWrapper>
        </S.SubTitleContainer>
      </S.Header>

      <S.VendorSection>
        <S.VendorContainer data-testid="free-db-cloud-vendor">
          <FreeDbCloudVendor
            freePlans={freePlans}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            vendorRegionPlanMapper={vendorRegionPlanMapper}
            {...newRedisOptInProps}
          />
        </S.VendorContainer>
      </S.VendorSection>

      <S.Banner>
        <FreeDbBanner />
      </S.Banner>

      <S.Cta>
        <S.ButtonsContainer>
          <TextButton data-testid="free-db--button-create-custom-database" onClick={handleOnSkip}>
            {t(keyPrefix.buttonSeeMorePlans)}
          </TextButton>
          <Button
            variant="primary"
            size="medium"
            data-testid="free-db--button-create-free-database"
            onClick={handleOnClickCreateFreeDatabase}
          >
            {t(keyPrefix.buttonGetStarted)}
          </Button>
        </S.ButtonsContainer>
      </S.Cta>
    </>
  );
};

export default FreeDbForm;
