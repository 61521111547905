import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { RedisLogoDarkMinIcon } from '@redislabsdev/redis-ui-icons/multicolor';
import { BackupDestinationOption } from '../RemoteBackup.types';

export const backupDestinationsOptions: BackupDestinationOption[] = [
  {
    id: 's3',
    name: 'AWS S3',
    protocol: 's3:',
    pattern: 's3://bucketname/[path/]',
    icon: IconNamesEnum.AMAZON
  },
  {
    id: 'gcs',
    name: 'Google Cloud Storage',
    protocol: 'gs:',
    pattern: 'gs://bucketname/[path/]',
    icon: IconNamesEnum.GOOGLE_CLOUD
  },
  {
    id: 'azure',
    name: 'Azure Blob Storage',
    protocol: 'abs:',
    pattern: 'abs://:storage_account_access_key@storage_account_name/[container/]',
    icon: IconNamesEnum.AZURE
  },
  {
    id: 'ftp',
    name: 'FTP',
    protocol: 'ftp:',
    pattern: '<ftp|ftps>://[username][:password]@[hostname][:port]/[path/]',
    icon: IconNamesEnum.FTP
  }
];

export const redisDefaultBackupDestination: BackupDestinationOption = {
  id: 'defaultBackupRepo',
  name: 'Redis-managed repo',
  protocol: '',
  pattern: '',
  iconComponentType: RedisLogoDarkMinIcon
};

export const getBackupDestinationsOptions = (
  isBackupRedislabsAllowed: boolean
): BackupDestinationOption[] => {
  if (isBackupRedislabsAllowed) {
    return [redisDefaultBackupDestination, ...backupDestinationsOptions];
  }

  return backupDestinationsOptions;
};
