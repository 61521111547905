import { useState } from 'react';
import { useSelector } from 'react-redux';
import { authIdSelector } from 'store/auth/auth.selectors';
import { usersQueries } from 'queryClient/users/users.queries';
import * as S from './FreeDb.style';
import useRegions from '../../../hooks/useRegions';
import { useEssentialPlansQuery } from '../../../hooks/useEssentialPlansQuery/useEssentialPlansQuery';
import FreeDbForm from './components/FreeDbForm/FreeDbForm';

const FreeDb = () => {
  const [showLoader, setShowLoader] = useState(false);
  const regionsQuery = useRegions();
  const plansQuery = useEssentialPlansQuery();
  const userId = useSelector(authIdSelector);
  const usersQuery = usersQueries.useUser(Number(userId));
  const isLoadingData =
    regionsQuery.isInitialLoading || plansQuery.isInitialLoading || usersQuery.isInitialLoading;

  return (
    <S.Container data-testid="free-db-container">
      {showLoader ? (
        <S.FreeDBLoaderContainer data-testid="free-db-loader">
          <S.Loader />
        </S.FreeDBLoaderContainer>
      ) : (
        <>
          <S.ImageLogo />
          <S.Form>
            {isLoadingData ? <S.Loader /> : <FreeDbForm setShowLoader={setShowLoader} />}
          </S.Form>
        </>
      )}
    </S.Container>
  );
};

export default FreeDb;
