import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

export const getClustersBySubId = async (subscriptionid: number) => {
  const { data } = await RedisApiAxiosInstance.get<{ cluster: Cluster }>(ApiPaths.CLUSTERS, {
    params: { subscriptionid }
  });

  return data.cluster;
};

export const getModulesByClusterId = async (clusterId: number) => {
  const { data } = await RedisApiAxiosInstance.get<{ modules: Module[] }>(ApiPaths.MODULES, {
    params: { cluster_id: clusterId }
  });

  return data.modules;
};
