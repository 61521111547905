import { i18translation } from 'locale/translations/i18NsPaths';
import { keyBy, sortBy } from 'lodash';

const alertMessagesKeyPrefix = i18translation.databaseConfigTab.alertMessages;
export const alertToMessageMapper: { [key in DbAlertName]: string } = {
  dataset_size: alertMessagesKeyPrefix.datasetSize,
  datasets_size: alertMessagesKeyPrefix.multipleDatasetSize,
  throughput_higer_than: alertMessagesKeyPrefix.throughputHigherThan,
  throughput_lower_than: alertMessagesKeyPrefix.throughputLowerThan,
  latency: alertMessagesKeyPrefix.latency,
  backup_delayed: alertMessagesKeyPrefix.backupDelayed,
  connections_limit: alertMessagesKeyPrefix.connectionsLimit,
  syncsource_error: alertMessagesKeyPrefix.syncsourceError,
  syncsource_lag: alertMessagesKeyPrefix.syncsourceLag,
  syncer_unable_to_sync: alertMessagesKeyPrefix.syncerUnableToSync,
  high_syncer_lag: alertMessagesKeyPrefix.highSyncerLag,
  alerts_tooltip: alertMessagesKeyPrefix.alertsTooltip
};

const alertValuesKeyPrefix = i18translation.databaseConfigTab.alertValues;
export const alertToValueMapper: { [key in DbAlertName]: string } = {
  dataset_size: alertValuesKeyPrefix.datasetSize,
  datasets_size: alertValuesKeyPrefix.multipleDatasetSize,
  throughput_higer_than: alertValuesKeyPrefix.throughputHigherThan,
  throughput_lower_than: alertValuesKeyPrefix.throughputLowerThan,
  latency: alertValuesKeyPrefix.latency,
  backup_delayed: alertValuesKeyPrefix.backupDelayed,
  connections_limit: alertValuesKeyPrefix.connectionsLimit,
  syncsource_error: alertValuesKeyPrefix.syncsourceError,
  syncsource_lag: alertValuesKeyPrefix.syncsourceLag,
  syncer_unable_to_sync: alertValuesKeyPrefix.syncerUnableToSync,
  high_syncer_lag: alertValuesKeyPrefix.highSyncerLag,
  alerts_tooltip: alertValuesKeyPrefix.alertsTooltip
};

const alertInputsKeyPrefix = i18translation.databaseConfigTab.alertInputs;
export const alertToInputMapper: { [key in DbAlertName]: string } = {
  dataset_size: alertInputsKeyPrefix.memoryLimit,
  datasets_size: alertInputsKeyPrefix.planLimit,
  throughput_higer_than: alertInputsKeyPrefix.opsSec,
  throughput_lower_than: alertInputsKeyPrefix.opsSec,
  latency: alertInputsKeyPrefix.mSec,
  backup_delayed: alertInputsKeyPrefix.blank,
  connections_limit: alertInputsKeyPrefix.planLimit,
  syncsource_error: alertInputsKeyPrefix.seconds,
  syncsource_lag: alertInputsKeyPrefix.seconds,
  syncer_unable_to_sync: alertInputsKeyPrefix.blank,
  high_syncer_lag: alertInputsKeyPrefix.blank,
  alerts_tooltip: alertInputsKeyPrefix.blank
};

export const alertToDataTestIdsMapper = {
  throughput_higer_than: 'throughput-is-higher-than',
  throughput_lower_than: 'throughput-is-lower-than',
  latency: 'latency-is-higher-than',
  dataset_size: 'dataset-size-has-reached',
  datasets_size: 'total-size-of-datasets-under-this-plan-has-reached',
  connections_limit: 'number-of-connections-has-reached',
  syncsource_error: 'syncsource-error',
  syncsource_lag: 'syncsource-lag'
};

export const getDbAlertsFromPlanAlerts = (
  planAlerts: PlanAlert[] = [],
  usePlanDefaults = false
): DbAlert[] =>
  planAlerts.map(({ default_active, default_value, ...rest }) => {
    return {
      active: usePlanDefaults ? default_active : false,
      value: default_value,
      default_value,
      ...rest
    };
  });

export const createInitialDbAlerts = (
  alerts: DbAlert[] = [],
  planAlerts: PlanAlert[] = []
): DbAlert[] => {
  const alertsCopy = [...alerts];
  const mappedCurrentAlerts = keyBy(alerts, 'name');
  const dbAlertsFromPlanAlerts = getDbAlertsFromPlanAlerts(planAlerts);

  dbAlertsFromPlanAlerts.forEach((alertFromPlan) => {
    if (!mappedCurrentAlerts[alertFromPlan.name]) {
      alertsCopy.push(alertFromPlan);
    }
  });

  return sortBy(alertsCopy, ['name']);
};
