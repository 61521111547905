import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { CloudVendorLabel } from './CloudAndRegionSelection.style';
import CloudSelect from './components/CloudSelect/CloudSelect';
import CreationRegionSelect from './components/CreationRegionSelect/CreationRegionSelect';
import { CloudAndRegionSelectionProps } from './CloudAndRegionSelection.types';

const CloudAndRegionSelection = ({
  allRegions,
  onCloudSelect,
  onRegionSelect,
  selectedRegion,
  cloudProviders,
  selectedProvider,
  isSupportingNewRedisOptIn,
  onNewRedisOptInClick,
  withMoreRegionsPageLink = false,
  isOptedToNewRedis,
  redisOptInVersion,
  newRedisOptInRegionIds
}: CloudAndRegionSelectionProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.createSubscription.cloudVendor;

  return (
    <>
      <div>
        <CloudVendorLabel label={t(keyPrefix.title)} />
        <CloudSelect {...{ selectedProvider, cloudProviders, onCloudSelect }} />
      </div>
      <CreationRegionSelect
        key={selectedProvider}
        {...{
          selectedRegion,
          onRegionSelect,
          allRegions,
          onNewRedisOptInClick,
          withMoreRegionsPageLink,
          isOptedToNewRedis,
          isSupportingNewRedisOptIn,
          redisOptInVersion,
          newRedisOptInRegionIds
        }}
      />
    </>
  );
};

export default CloudAndRegionSelection;
