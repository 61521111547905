import {
  cloudProviders,
  CloudProviders,
  DISABLED_CLOUD_STRING
} from '../../../../../../../components/CloudProvider/CloudProvider.types';
import { EssentialPlan } from '../../../../../../../store/createSubscription/essentials/essentials.types';

export const getAllAvailableProviders = (
  essentialPlans: EssentialPlan[],
  isOptedToNewRedis: boolean
): CloudProviders[] => {
  const isCloudProviderExists = (provider: CloudProviders) =>
    essentialPlans.some((plan) => plan.cloud.toLowerCase() === provider);

  return cloudProviders.reduce((providers, provider) => {
    if (isCloudProviderExists(provider)) {
      providers.push(provider);
    } else if (isOptedToNewRedis) {
      providers.push(`${provider}${DISABLED_CLOUD_STRING}`);
    }

    return providers;
  }, [] as CloudProviders[]);
};
