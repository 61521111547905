import styled from 'styled-components/macro';
import { Select } from '@redislabsdev/redis-ui-components';

export const Title = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.semantic.color.text.neutral700};
`;

export const RegionSelectContainer = styled.div`
  z-index: 3;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const SelectContentOptionList = styled(Select.Content.OptionList)`
  max-height: 140px;
`;

export const SelectContentBottom = styled.div`
  padding: 8px 16px 0;
  border-top: 1px solid ${({ theme }) => theme.semantic.color.border.neutral400};
`;
