import {
  Card,
  CardUnderText,
  CardWrapper,
  WhiteCircle
} from 'components/Shared/IconCard/IconCard.style';
import { RegionSelectContainer } from 'screens/CreateSubscription/components/CloudAndRegionSelection/components/CreationRegionSelect/CreationRegionSelect.style';
import styled, { css } from 'styled-components/macro';
import * as CloudAndRegionSelectionStyle from 'screens/CreateSubscription/components/CloudAndRegionSelection/CloudAndRegionSelection.style';
import { Typography } from '@redislabsdev/redis-ui-components';

const SMALL_SCREEN_HEIGHT = 820;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

export const Cta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 4rem;
`;

const VendorCommon = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const VendorSection = styled.div`
  ${VendorCommon};
  width: 100%;
  height: auto;
  margin-top: 5.6rem;

  @media only screen and (max-height: ${`${SMALL_SCREEN_HEIGHT}px`}) {
    margin-top: 3.2rem;
  }
`;

export const VendorContainer = styled.div`
  ${VendorCommon};
  width: 100%;
  height: auto;

  ${CloudAndRegionSelectionStyle.Title} {
    text-align: left;
    margin-bottom: 1rem;
  }

  ${CardWrapper} {
    width: 15.5rem;
  }

  ${Card} {
    width: 15.5rem;
    height: 12rem;
  }

  ${CardUnderText} {
    height: auto;
    color: ${({ theme }) => theme.semantic.color.text.primary500};
  }

  ${WhiteCircle} {
    & svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-top: -0.55rem;
      margin-left: -0.55rem;
    }
  }

  ${RegionSelectContainer} {
    margin-top: 3.2rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  height: 100%;
`;

export const Title = styled(Typography.Heading)`
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
`;

export const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  padding-top: 1.2rem;
  font-weight: 600;
`;
export const SubTitle = styled(Typography.Body)`
  display: flex;
  flex-direction: row;
`;

export const IconTextWrapper = styled(Typography.Body)`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
`;
export const Banner = styled.div`
  width: 100%;
  margin-top: 4rem;

  @media only screen and (max-height: ${`${SMALL_SCREEN_HEIGHT}px`}) {
    margin-top: 2.4rem;
  }
`;
