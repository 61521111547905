import {
  Awss3Icon,
  AzureIcon,
  GooglecloudIcon,
  IconType
} from '@redislabsdev/redis-ui-icons/multicolor';
import { CloudProviders } from './CloudProvider.types';

export const getIconAndTextByProviderType = (cloudProviderType: CloudProviders) => {
  const cloudProviderIconsObj: Record<CloudProviders, IconType> = {
    aws: Awss3Icon,
    'aws-disabled': Awss3Icon,
    azure: AzureIcon,
    'azure-disabled': AzureIcon,
    'azure-link': AzureIcon,
    gcp: GooglecloudIcon,
    'gcp-disabled': GooglecloudIcon
  };

  return cloudProviderIconsObj[cloudProviderType];
};
