export const protocolItems: { key: string; value: DatabaseType }[] = [
  { key: 'Redis', value: 'redis' },
  { key: 'Memcached', value: 'memcached' }
];

export const dataPersistenceItems = [
  { key: 'None', value: 'disabled' },
  { key: 'Append Only File (AOF) - fsync every 1 sec', value: 'aof' },
  { key: 'Snapshot, every 1 hour', value: 'snapshot:3600' },
  { key: 'Snapshot, every 6 hour', value: 'snapshot:21600' },
  { key: 'Snapshot, every 12 hour', value: 'snapshot:43200' }
] as const;

interface EvictionPolicyItem {
  key: string;
  value: EvictionPolicy;
  lfu: boolean;
  redisDefault: boolean;
  memcachedDefault: boolean;
}
export const noEvictionItem: EvictionPolicyItem = {
  key: 'no eviction',
  value: 'noeviction',
  lfu: false,
  redisDefault: false,
  memcachedDefault: false
};

export const redisFlexUnsupportedPolicies = ['allkeys-random', 'volatile-random'];

export const getDataEvictionPolicyItems = (isRedisFlex: boolean = false): EvictionPolicyItem[] => {
  const evictionPolicyItems: EvictionPolicyItem[] = [
    {
      key: 'allkeys-lru',
      value: 'allkeys-lru',
      lfu: false,
      redisDefault: false,
      memcachedDefault: true
    },
    {
      key: 'allkeys-lfu',
      value: 'allkeys-lfu',
      lfu: true,
      redisDefault: false,
      memcachedDefault: false
    },
    {
      key: 'allkeys-random',
      value: 'allkeys-random',
      lfu: false,
      redisDefault: false,
      memcachedDefault: false
    },
    {
      key: 'volatile-lru',
      value: 'volatile-lru',
      lfu: false,
      redisDefault: true,
      memcachedDefault: false
    },
    {
      key: 'volatile-lfu',
      value: 'volatile-lfu',
      lfu: true,
      redisDefault: false,
      memcachedDefault: false
    },
    {
      key: 'volatile-random',
      value: 'volatile-random',
      lfu: false,
      redisDefault: false,
      memcachedDefault: false
    },
    {
      key: 'volatile-ttl',
      value: 'volatile-ttl',
      lfu: false,
      redisDefault: false,
      memcachedDefault: false
    },
    noEvictionItem
  ];

  return isRedisFlex
    ? evictionPolicyItems.filter((item) => !redisFlexUnsupportedPolicies.includes(item.value))
    : evictionPolicyItems;
};
