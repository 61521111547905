import { ExtendedAxiosError } from '../../../types/interfaces';
import { extractErrorCode } from '../../../utils/helpers/extractError';
import {
  unauthorizedStatus,
  serviceUnavailableStatus,
  notAcceptableStatus,
  unprocessableEntity,
  badRequestStatus,
  notFoundStatus
} from '../../../utils/constants/api/statuses';

const httpStatusesToIgnore = [
  unauthorizedStatus,
  serviceUnavailableStatus,
  notAcceptableStatus,
  unprocessableEntity
];

const shouldFilterHttpError = (error: ExtendedAxiosError, isGeneralErrorMessage: boolean) => {
  return (
    isMappedBadRequestError(isGeneralErrorMessage, error.response.status) ||
    isIgnoreStatusCode(error.response.status) ||
    isExpectedOktaError(error) ||
    isLogoutError(error)
  );
};

const isIgnoreStatusCode = (statusCode: number) => httpStatusesToIgnore.includes(statusCode);

const isMappedBadRequestError = (isGeneralErrorMessage: boolean, statusCode: number) => {
  return !isGeneralErrorMessage && statusCode === badRequestStatus;
};

const isExpectedOktaError = (error: ExtendedAxiosError) => {
  const errorCode = extractErrorCode(error.response.data);

  return error.response.status === notFoundStatus && errorCode === 'saml-config-not-found';
};

const isLogoutError = (error: ExtendedAxiosError) => {
  return error.response.config.url === '/api/v1/login' && error.response.config.method === 'DELETE';
};

export default shouldFilterHttpError;
