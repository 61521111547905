import styled from 'styled-components/macro';

export const Container = styled.div`
  align-self: flex-start;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 1.4rem;
  color: ${({ theme }) => theme.semantic.color.text.neutral700};
`;

export const RegionValue = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  margin-left: 0.5rem;
`;

export const RegionContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.semantic.color.text.neutral700};
  padding-left: 1px;
  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.semantic.color.text.neutral800};
  }
  p {
    margin: 0;
  }
`;

export const RegionId = styled.span`
  margin-left: 1rem;
`;
