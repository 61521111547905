import styled from 'styled-components/macro';
import { FormField } from '@redislabsdev/redis-ui-components';
import { FormInput as FormInputComponent } from '../../FormControls';

export const FormFieldCompose = styled(FormField.Compose)<{ $width?: string }>`
  width: ${({ $width }) => $width};
`;

export const Flex = styled.span`
  display: flex;
  align-items: center;
`;

export const FormInput = styled(FormInputComponent)<{ setWidth: string; bottomSpacing: boolean }>`
  width: ${({ setWidth }) => setWidth || '100%'};
  ${({ bottomSpacing }) => bottomSpacing && `margin-bottom: 1.6rem`};
`;
