import { parametersQueries } from 'queryClient/parameters/parameters.queries';
import { useLocation } from 'react-router-dom';
import { routes } from 'utils/constants/routes';

const useGetRegionUrl = () => {
  const { pathname } = useLocation();
  const { systemParams } = parametersQueries.useSystemParameters();

  if (!systemParams?.more_regions_page_link) {
    return '';
  }

  const readMoreLinkUrl = new URL(systemParams.more_regions_page_link.toString());

  switch (pathname) {
    case routes.newCustomer.freeDb:
    case routes.createSubscription.essential:
      readMoreLinkUrl.searchParams.set('subscriptionType', 'fixed');
      break;
    case routes.createSubscription.pro.setup:
      readMoreLinkUrl.searchParams.set('subscriptionType', 'flexible');
      break;
  }

  return readMoreLinkUrl.href;
};

export default useGetRegionUrl;
