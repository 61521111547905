import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { RegionDisplayField } from '../../../../../../SubscriptionTypes/Pro/Steps/components/Setup/components/MultiRegionCloudVendor/MultiRegionCloudVendor.style';
import * as S from './RenderRegionItem.style';
import { NewRedisOptInProps } from '../../CreationRegionSelect.types';

interface RenderRegionItemProps
  extends Pick<NewRedisOptInProps, 'isSupportingNewRedisOptIn' | 'redisOptInVersion'> {
  region: CloudRegion;
  newRedisOptInRegionIds: number[];
}

const RenderRegionItem = ({
  region,
  isSupportingNewRedisOptIn,
  newRedisOptInRegionIds,
  redisOptInVersion
}: RenderRegionItemProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newRedisOptIn;

  const isRegionNewRedis = (regionId: number) => newRedisOptInRegionIds.includes(regionId);

  return (
    <RegionDisplayField
      region={region?.name}
      tailNode={
        isSupportingNewRedisOptIn &&
        isRegionNewRedis(region?.region_id) && (
          <S.OptInText>
            {`(${t(keyPrefix.regionItemNewRedisText, { version: redisOptInVersion })})`}
          </S.OptInText>
        )
      }
    />
  );
};

export default RenderRegionItem;
