import { FC } from 'react';
import { Tooltip } from '@redislabsdev/redis-ui-components';
import { usePrefixedTranslation } from '../../hooks';
import CloudProviderCard from './components/CloudProviderCard';
import { CloudProviders, DISABLED_CLOUD_STRING, LINK_CLOUD_STRING } from './CloudProvider.types';
import { getIconAndTextByProviderType } from './utils';
import * as S from './CloudProvider.style';

interface IProps {
  cloudProvider: CloudProviders;
}

const CloudProvider: FC<IProps> = ({ cloudProvider }) => {
  const { prefixedT } = usePrefixedTranslation('cloudProviderCard');
  const Icon = getIconAndTextByProviderType(cloudProvider);
  const isCardDisabled = cloudProvider.includes(DISABLED_CLOUD_STRING);
  const isLink = cloudProvider.includes(LINK_CLOUD_STRING);
  const cloudVendorLabel = prefixedT(
    cloudProvider.replace(new RegExp(`(${DISABLED_CLOUD_STRING}|${LINK_CLOUD_STRING})`), '')
  );

  const cloudVendorBox = (
    <CloudProviderCard
      IconComponent={Icon}
      cloudVendorLabel={cloudVendorLabel}
      isLink={isLink}
      cloudProvider={cloudProvider}
      disabled={isCardDisabled}
    />
  );

  return isLink ? (
    <Tooltip content={prefixedT(`linkText.${cloudProvider.replace(LINK_CLOUD_STRING, '')}`)}>
      <S.TooltipTriggerWrapper>{cloudVendorBox}</S.TooltipTriggerWrapper>
    </Tooltip>
  ) : (
    cloudVendorBox
  );
};

export default CloudProvider;
