import { BoxSelectionGroupBox } from '@redislabsdev/redis-ui-components';
import CloudProvider from '../../../../../../components/CloudProvider/CloudProvider';
import {
  CloudProviders,
  LINK_CLOUD_STRING
} from '../../../../../../components/CloudProvider/CloudProvider.types';
import * as S from './CloudSelect.style';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';

interface CloudSelectProps {
  cloudProviders: CloudProviders[];
  onCloudSelect: (provider: CloudProviders) => void;
  selectedProvider?: CloudProviders;
}

const CloudSelect = ({ cloudProviders, onCloudSelect, selectedProvider }: CloudSelectProps) => {
  const { systemParams } = parametersQueries.useSystemParameters();

  const handleCloudBoxesChange = (selectedBoxes: BoxSelectionGroupBox[]) => {
    const newSelectedVendorArray = selectedBoxes.filter((item) => item.id !== selectedProvider);

    if (!newSelectedVendorArray.length) return;

    const isLink = newSelectedVendorArray[0].id.includes(LINK_CLOUD_STRING);
    const azureLink = systemParams.azure_marketplace_flexible_link;
    if (isLink && azureLink) {
      window.open(azureLink, '_blank');
    } else {
      onCloudSelect(newSelectedVendorArray[0].id as CloudProviders);
    }
  };

  return (
    <S.CloudVendorBoxSelectionGroup
      selectedBoxes={[{ id: selectedProvider, label: selectedProvider }]}
      onBoxesChange={handleCloudBoxesChange}
      data-testid="list--cloud-vendors"
    >
      {cloudProviders.map((provider) => (
        <CloudProvider key={provider} cloudProvider={provider} />
      ))}
    </S.CloudVendorBoxSelectionGroup>
  );
};

export default CloudSelect;
