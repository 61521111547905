import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { Typography, Tooltip } from '@redislabsdev/redis-ui-components';
import { REDIS_VERSION_PREVIEW_LINK } from 'utils/constants/docLinks';
import { getDataTestId } from '../../../../../../../../../../utils/dataAttributesHelpers';
import * as S from './NewRedisOptInTooltipContent.style';

interface Props {
  redisOptInVersion: string;
}

export const NewRedisOptInTooltipContent = ({ redisOptInVersion }: Props) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newRedisOptIn;

  return (
    <>
      <Typography.Heading
        style={{ fontSize: '16px' }}
        {...getDataTestId('readonlylabel', 'redis-7-explanation-title')}
      >
        {t(keyPrefix.optInTooltipTitle, { version: redisOptInVersion })}
      </Typography.Heading>
      <S.TooltipText
        style={{ fontSize: '14px' }}
        color="neutral700"
        {...getDataTestId('readonlyvalue', 'redis-7-explanation')}
      >
        {t(keyPrefix.optInInfoDescription)}
      </S.TooltipText>
      <Tooltip.Card.Footer linkText={t(keyPrefix.readMore)} link={REDIS_VERSION_PREVIEW_LINK} />
    </>
  );
};
