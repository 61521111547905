import { Route, Switch } from 'react-router-dom';
import { routes } from 'utils/constants/routes';
import FreeDb from './FreeDb/FreeDb';
import NewCustomerNewDatabase from './NewCustomerNewDatabase/NewCustomerNewDatabase';

const NewCustomer = () => {
  return (
    <Switch>
      <Route exact path={routes.newCustomer.freeDb} component={FreeDb} />
      <Route exact path={routes.newCustomer.newDatabase} component={NewCustomerNewDatabase} />
    </Switch>
  );
};

export default NewCustomer;
