import styled from 'styled-components/macro';
import { Label } from '@redislabsdev/redis-ui-components';

export const Title = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
`;

export const CloudVendorLabel = styled(Label)`
  margin-bottom: 0.5rem;
`;
