import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useLocation } from 'react-router-dom';
import {
  Label,
  Select,
  SelectOption,
  SelectValueRenderParams
} from '@redislabsdev/redis-ui-components';
import { CreationRegionSelectProps } from './CreationRegionSelect.types';
import NewRedisOptInToggle from './components/NewRedisOptInToggle/NewRedisOptInToggle';
import RenderRegionItem from './components/RenderRegionItem/RenderRegionItem';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';
import { accountIdSelector } from '../../../../../../store/auth/auth.selectors';
import getIsRequestMoreRegionsVisible from './utils/getIsRequestMoreRegionsVisible';
import useGetRegionUrl from './hooks/useGetRegionUrl';
import ReadMore from '../../../../../../components/ReadMore/ReadMore';
import * as S from './CreationRegionSelect.style';

type RegionOption = SelectOption & { region: CloudRegion; searchTags: string };

const CreationRegionSelect = ({
  selectedRegion,
  onRegionSelect,
  isSupportingNewRedisOptIn,
  onNewRedisOptInClick,
  withMoreRegionsPageLink,
  isOptedToNewRedis,
  redisOptInVersion,
  newRedisOptInRegionIds,
  allRegions,
  dropdownProps,
  withTitle = true
}: CreationRegionSelectProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const keyPrefix = i18translation.createSubscription.cloudVendor;
  const { systemParams } = parametersQueries.useSystemParameters();
  const readMoreLinkUrl = useGetRegionUrl();
  const isOptInToNewRedisDisabled = !allRegions.some((region) =>
    (newRedisOptInRegionIds || []).includes(Number(region.id))
  );

  const accountId = useSelector(accountIdSelector);

  const isRequestMoreRegionsVisible = getIsRequestMoreRegionsVisible({
    withMoreRegionsPageLink,
    systemParams,
    currentAccountId: Number(accountId),
    isOptedToNewRedis,
    pathname
  });

  const options: RegionOption[] = useMemo(
    () =>
      allRegions
        .map((region) =>
          region
            ? {
                region,
                value: region.id,
                searchTags: [region.city_name, region.country_name, region.name]
                  .join('|')
                  .toLowerCase()
              }
            : null
        )
        .filter(Boolean),
    [allRegions]
  );

  const compareRegion = (option: RegionOption, searchValue: string) =>
    option.searchTags.includes(searchValue);

  const renderRegionValue = ({ option }: SelectValueRenderParams<RegionOption>) => (
    <RenderRegionItem
      region={option.region}
      isSupportingNewRedisOptIn={isSupportingNewRedisOptIn}
      newRedisOptInRegionIds={newRedisOptInRegionIds}
      redisOptInVersion={redisOptInVersion}
    />
  );

  const handleSelectRegion = (value: string) => {
    const selected = allRegions.find((region) => region.id === value);
    selected && onRegionSelect(selected);
  };

  const moreRegions = isRequestMoreRegionsVisible && (
    <S.SelectContentBottom>
      <ReadMore readMoreLink={readMoreLinkUrl}>{t(keyPrefix.moreRegionsPageLinkText)}</ReadMore>
    </S.SelectContentBottom>
  );

  return (
    <S.RegionSelectContainer data-testid="single-region-select">
      <S.HeaderContainer>
        {withTitle && <Label label={t(keyPrefix.selectRegion)} />}
        {isSupportingNewRedisOptIn && (
          <NewRedisOptInToggle
            onNewRedisOptInClick={onNewRedisOptInClick}
            isOptedToNewRedis={isOptedToNewRedis}
            redisOptInVersion={redisOptInVersion}
            isOptInToNewRedisDisabled={isOptInToNewRedisDisabled}
          />
        )}
      </S.HeaderContainer>
      <Select.Compose
        {...dropdownProps}
        options={options}
        value={selectedRegion?.id}
        onChange={handleSelectRegion}
        customCompare={compareRegion}
      >
        <Select.Trigger
          id="selection-area-button--region-select"
          data-testid="selectbox--region"
          valueRender={renderRegionValue}
        />
        <Select.Content.Compose data-testid="select-list-container">
          <Select.Content.Search data-testid="textinput--find-entries-by-keyword" />
          <S.SelectContentOptionList
            data-testid="listbox--region"
            optionValueRender={renderRegionValue}
          />
          {moreRegions}
        </Select.Content.Compose>
      </Select.Compose>
    </S.RegionSelectContainer>
  );
};

export default CreationRegionSelect;
