import { cloneElement } from 'react';
import { useField } from 'formik';
import { FormField } from '@redislabsdev/redis-ui-components';
import LabelWithLink from '../LabelWithLink/LabelWithLink';
import { FormInputGenericProps } from './FormInput.types';
import * as S from './FormInput.style';

const FormInputGeneric = ({
  className,
  testId,
  fieldName,
  width,

  textUnderInput,
  id = fieldName,

  label,
  optional,
  required,
  infoIconProps,
  readOnly,
  readMore,

  children,

  useCustomOnChange = false,
  valueOverride,
  ...inputProps
}: FormInputGenericProps) => {
  const [{ onChange, ...field }, meta] = useField(fieldName);

  return (
    <S.FormFieldCompose className={className} data-testid={`form-container--${testId}`}>
      <LabelWithLink
        label={label}
        optional={optional}
        required={required}
        testId={testId}
        readMore={readMore}
        infoIconProps={infoIconProps}
        readOnly={readOnly}
        htmlFor={id}
      />
      <FormField.InputContainer style={{ width }}>
        {cloneElement(children, {
          id,
          name: fieldName,
          error: meta.error,
          inputTestId: testId,
          ...field,
          ...(valueOverride && { value: valueOverride }),
          ...inputProps,
          ...(!useCustomOnChange && { onChange })
        })}
      </FormField.InputContainer>
      <FormField.AdditionalText>{textUnderInput}</FormField.AdditionalText>
    </S.FormFieldCompose>
  );
};

export default FormInputGeneric;
