import { isEmpty } from 'lodash';
import { EssentialPlan } from '../../store/createSubscription/essentials/essentials.types';

export const convertToEssentialPlans = (
  plans: Plan[],
  selectablePlans: number[],
  subscription?: Subscription
): EssentialPlan[] => {
  if (isEmpty(selectablePlans)) {
    return [];
  }

  const selectablePlansObject = selectablePlans.reduce((acc, curr) => {
    acc[curr] = true;

    return acc;
  }, {} as Record<string, boolean>);

  return plans.map((plan) => ({
    ...plan,
    isSelectable: !!selectablePlansObject[plan.id] || plan.id === subscription?.plan
  }));
};
