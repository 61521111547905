import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { Tooltip, Switch, Typography, Badge } from '@redislabsdev/redis-ui-components';
import { InfoIcon } from '@redislabsdev/redis-ui-icons';
import { NewRedisOptInProps } from '../../CreationRegionSelect.types';
import { getDataTestId } from '../../../../../../../../utils';
import { NewRedisOptInTooltipContent } from './components/NewRedisOptInTooltipContent/NewRedisOptInTooltipContent';
import * as S from './NewRedisOptInToggle.style';

interface NewRedisOptInToggleProps
  extends Required<
    Pick<NewRedisOptInProps, 'onNewRedisOptInClick' | 'isOptedToNewRedis' | 'redisOptInVersion'>
  > {
  isOptInToNewRedisDisabled: boolean;
}

const NewRedisOptInToggle = ({
  onNewRedisOptInClick,
  isOptedToNewRedis,
  redisOptInVersion,
  isOptInToNewRedisDisabled
}: NewRedisOptInToggleProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newRedisOptIn;
  const handleRedis7OptInClick = () => {
    onNewRedisOptInClick(!isOptedToNewRedis);
  };

  return (
    <S.Container>
      <Switch
        variant={isOptInToNewRedisDisabled ? 'disabled' : 'enabled'}
        on={isOptedToNewRedis}
        onClickSwitch={handleRedis7OptInClick}
        {...getDataTestId('switch', 'redis-7-opt-in')}
      />
      <Typography.Body
        color={isOptInToNewRedisDisabled ? 'neutral500' : 'neutral700'}
        {...getDataTestId('readonlylabel', 'redis-7-opt-in')}
      >
        {t(keyPrefix.optInToggleLabel, {
          version: redisOptInVersion
        })}
      </Typography.Body>
      <Tooltip
        maxWidth="350px"
        content={<NewRedisOptInTooltipContent redisOptInVersion={redisOptInVersion} />}
        interactive
        withButton
      >
        <InfoIcon />
      </Tooltip>
      <Badge label={t(keyPrefix.newBadge)} variant="notice" />
    </S.Container>
  );
};

export default NewRedisOptInToggle;
