import styled from 'styled-components/macro';
import { useTheme } from '@redislabsdev/redis-ui-styles';
import { MultiSelect } from '@redislabsdev/redis-ui-components';
import {
  RegionId,
  RegionValue
} from '../../../../../../../../../components/RegionDisplayField/RegionDisplayField.style';
import RegionDisplayFieldComponent from '../../../../../../../../../components/RegionDisplayField/RegionDisplayField';

export const RegionItemContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RegionDisplayField = styled(RegionDisplayFieldComponent)`
  ${RegionValue}, ${RegionId} {
    margin-inline-start: 0.4rem;
  }

  [data-state='checked'] & {
    span {
      color: ${({ theme }) => theme.semantic.color.text.primary600};
    }
  }
`;

export const MultiSelectContentOptionList = styled(MultiSelect.Content.OptionList)`
  max-height: 140px;
`;

export const MultiSelectContentBottom = styled.div`
  padding: 8px 16px 0;
  border-top: 1px solid ${() => useTheme().semantic.color.border.neutral400};
`;
