import { Typography } from '@redislabsdev/redis-ui-components';
import { NewTabIcon } from '@redislabsdev/redis-ui-icons';
import { CloudProviderCardProps } from './CloudProviderCard.types';
import * as S from './CloudProviderCard.style';

const CloudProviderCard = ({
  cloudProvider,
  IconComponent,
  disabled,
  isLink,
  cloudVendorLabel
}: CloudProviderCardProps) => (
  <S.CloudProviderBox
    box={{ id: cloudProvider, label: cloudProvider, disabled }}
    data-testid={`${cloudProvider}-provider`}
  >
    <IconComponent customSize="32px" />
    <S.BoxLabel>
      <Typography.Body size="S" color="neutral700">
        {cloudVendorLabel}
      </Typography.Body>
      {isLink && <NewTabIcon size="S" />}
    </S.BoxLabel>
  </S.CloudProviderBox>
);

export default CloudProviderCard;
