import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { subscriptionByIdSelector } from '../store/subscriptions/subscriptions.selectors';
import useSubscriptionIdParam from './commonParams/useSubscriptionIdParam';

const useGetSubscriptionByParam = () => {
  const { subscriptionId } = useSubscriptionIdParam();
  const subscription: Subscription =
    useSelector(subscriptionByIdSelector(subscriptionId), isEqual) || {};

  return { subscription, subscriptionId };
};

export default useGetSubscriptionByParam;
