import { useState } from 'react';
import { FreeDbCloudVendorProps } from 'screens/NewCustomer/FreeDb/FreeDb.types';
import {
  getVendorRegionPlanMapper,
  getFreePlans,
  getFirstRegion,
  DEFAULT_VENDOR
} from 'screens/NewCustomer/FreeDb/utils/loginRelated';
import { CloudProviders } from 'components/CloudProvider/CloudProvider.types';
import useRegions from '../../../../../../hooks/useRegions';
import { useEssentialPlansQuery } from '../../../../../../hooks/useEssentialPlansQuery/useEssentialPlansQuery';
import { useFeatureFlags } from '../../../../../../components/FeatureFlagsProvider/hooks/useFeatureFlags';

const useNewRedisOptInCloudRegionSelectionProps = (): FreeDbCloudVendorProps => {
  const { getRegionByRegionName, getRegionsByProvider } = useRegions();
  const { data: plans } = useEssentialPlansQuery({ refetchOnMount: false });
  const [isOptedToNewRedis, setIsOptedToNewRedis] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<CloudProviders>(DEFAULT_VENDOR);
  const {
    flags: { rcePreviewVersion }
  } = useFeatureFlags();

  const freePlans = getFreePlans({
    plans,
    isOptedToNewRedis,
    newRedisRegions: rcePreviewVersion?.regions || []
  });

  const vendorRegionPlanMapper = getVendorRegionPlanMapper(freePlans);

  const [selectedRegion, setSelectedRegion] = useState<string>(() =>
    getFirstRegion(vendorRegionPlanMapper[DEFAULT_VENDOR], getRegionByRegionName)
  );

  const onNewRedisOptInClick = (isOptingIn: boolean) => {
    if (isOptingIn) {
      const firstNewRedisRegion = getRegionsByProvider(selectedVendor).find((region) =>
        (rcePreviewVersion?.regions || []).includes(Number(region.id))
      );

      if (firstNewRedisRegion) {
        setSelectedRegion(firstNewRedisRegion.name);
      }
    } else {
      const allAvailableFreePlans = getVendorRegionPlanMapper(getFreePlans({ plans }));
      setSelectedRegion(
        getFirstRegion(allAvailableFreePlans[selectedVendor], getRegionByRegionName)
      );
    }
    setIsOptedToNewRedis(isOptingIn);
  };

  return {
    onNewRedisOptInClick,
    isSupportingNewRedisOptIn: rcePreviewVersion?.isPreviewActive || false,
    isOptedToNewRedis,
    redisOptInVersion: rcePreviewVersion?.version || null,
    newRedisOptInRegionIds: rcePreviewVersion?.regions || [],
    selectedRegion,
    setSelectedRegion,
    selectedVendor,
    setSelectedVendor,
    vendorRegionPlanMapper,
    freePlans
  };
};

export default useNewRedisOptInCloudRegionSelectionProps;
