import styled from 'styled-components/macro';
import { BoxSelectionGroup } from '@redislabsdev/redis-ui-components';

export const CloudProviderBox = styled(BoxSelectionGroup.Item.Compose)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`;

export const BoxLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;
