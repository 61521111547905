import styled from 'styled-components/macro';
import { Loader as LoaderComponent } from '@redislabsdev/redis-ui-components';
import redisLogo from 'assets/icons/RedisLogo.svg';
import imageBg from './assets/bg.svg';

const MINIMAL_SCREEN_HEIGHT = 750;

export const Container = styled.div`
  position: absolute;
  z-index: 6;

  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f2f3fc;
  background-image: url(${imageBg});
  background-size: cover;
  background-position: 0% 50%;
`;

export const ImageLogo = styled.div`
  width: 10.3rem;
  height: 3.5rem;
  position: absolute;
  top: 4rem;
  left: 4.7rem;

  background-image: url(${redisLogo});
  background-size: contain;
`;

export const Form = styled.div`
  display: flex;
  width: 68rem;
  padding: 7.2rem 8rem;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.6rem;
  box-shadow: 0rem 0rem 4.4rem 0.6rem rgba(0, 0, 0, 0.12),
    0rem 0rem 0rem 0.01rem rgba(0, 0, 0, 0.03);
  position: absolute;
  background-color: ${({ theme }) => theme.semantic.color.background.neutral100};
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  z-index: 10;
  overflow: hidden;

  ::-webkit-scrollbar-track {
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media only screen and (max-height: ${`${MINIMAL_SCREEN_HEIGHT}px`}) {
    overflow-y: auto;
  }
`;
export const FreeDBLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: ${({ theme }) => theme.semantic.color.background.neutral100};
  width: 100%;
  height: 100%;
`;
export const Loader = styled(LoaderComponent)`
  height: 100%;
`;
