import styled from 'styled-components/macro';
import { CheckBoldIcon } from '@redislabsdev/redis-ui-icons';
import { RedisStackIcon } from '@redislabsdev/redis-ui-icons/multicolor';

const SMALL_SCREEN_HEIGHT = 820;

export const Banner = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: ${({ theme }) => theme.semantic.color.background.informative50};
  padding: 1.6rem 2.4rem;
`;

export const BannerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BannerLeftCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  @media only screen and (max-height: ${`${SMALL_SCREEN_HEIGHT}px`}) {
    gap: 0.4rem;
  }
`;

export const BannerRightCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-left: 6.4rem;
`;

export const CheckIcon = styled(CheckBoldIcon)`
  margin-right: 0.8rem;
  & > path {
    fill: ${({ theme }) => theme.semantic.color.icon.success500};
  }
`;

export const StackIcon = styled(RedisStackIcon)`
  margin-left: 0.4rem;
  margin-right: 0.4rem;
`;

export const Spacer = styled.div`
  margin-left: 0.4rem;
`;
